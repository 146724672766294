import PropertyCreatePage from '@/views/PropertyCreate/index.vue'
import CourseBtn from '@/components/CourseBtn.vue'
import NewGuideComponent from '@/components/NewGuide/index.vue'
export default {
	name: 'PropertyListPage',
	components: {
		PropertyCreatePage,
		CourseBtn,
		NewGuideComponent
	},
	data() {
		return {
			homeList: [],
			// 搜索条件
			propertyQuery: '',
			// 排序方式
			propertyOrderby: '',
			// 翻页配置
			pagesObj: {
				page: 1,
				pagesize: 12,
				total: 0
			},
			drawer: false,
			peNumLimit: false,
		}
	},
	computed: {
		guideSteps() {
			let guideDescConfig = this.$store.getters.guideDescConfig
			return [
				{
					element: '#create-pe-btn', popover: {
						description: guideDescConfig.property.create_property_tips_createBtn,
						side: 'left',
						align: 'center',
						showButtons: ['next'],
						nextBtnText: this.$t('guide.goCreate'),
						onNextClick: () => {
							this.createProperty()
						}
					}
				},
				{
					element: '#property_name', popover: {
						description: this.getStepDesc('name_' + this.$store.getters.guideScene),
						side: 'left',
						align: 'start',
						onPrevClick: () => {
							this.closeDrawer()
							window.guideDriver.movePrevious()
						}
					}
				},
				{
					element: '#property_abbr_name', popover: {
						description: this.getStepDesc('abbr_name_' + this.$store.getters.guideScene),
						side: 'left',
						align: 'center',
					}
				},
				{
					element: '#property_industry', popover: {
						description: guideDescConfig.property.create_property_tips_industry,
						side: 'left',
						align: 'center',
					},
				},
				{
					element: '#property_upload', popover: {
						description: guideDescConfig.property.create_property_tips_upload,
						side: 'left',
						align: 'center',
					},
				},
				{
					element: '#property_save', popover: {
						description: guideDescConfig.property.create_property_tips_confirm,
						side: 'top',
						align: 'end',
						nextBtnText: this.$t('guide.ok'),
						onNextClick: () => {
							const saveBtn = document.querySelector('#property_save')
							saveBtn && saveBtn.click()
						}
					},
				},
				{
					element: '.property-list-item', popover: {
						description: guideDescConfig.property.new_pe_here,
						side: 'top',
						align: 'center',
						prevBtnText: this.$t('guide.continueCreate'),
						nextBtnText: this.$t('guide.nextStage'),
						onPrevClick: () => {
							window.guideDriver.drive(0)
						},
						onNextClick: () => {
							window.guideDriver.destroy()
							this.$pubsub.publish('overviewStepEvent', { key: 'create_account', show: true })
						}
					},
				},
			]
		}
	},
	mounted() {
		this.homelist()
		this.initGuide()
		// 关闭抽屉事件
		this.$pubsub.subscribe('closeDrawerEvent', () => {
			this.drawer = false
		})
	},
	beforeDestroy() {
		this.$pubsub.unsubscribe('getGuideDescAndSettingFulfilledEvent')
		this.$pubsub.unsubscribe('setGuideSceneFulfilledEvent')
		this.$pubsub.unsubscribe('closeDrawerEvent')
	},
	methods: {
		// 初始化向导
		initGuide() {
			// 向导描述和配置加载完成
			this.$pubsub.subscribe('getGuideDescAndSettingFulfilledEvent', () => {
				if (!this.$store.getters.guideDescConfig.property) return
				window.guideDriver.setSteps(this.guideSteps)
				if (this.$store.getters.isShowGuide) {
					window.guideDriver.drive()
				} else {
					if (!this.$store.getters.guideScene) {
						this.$pubsub.publish('showSceneDialogEvent')
					} else {
						this.$pubsub.publish('overviewStepEvent', {key: 'create_property'})
					}
				}

			})
			// 切换场景完成，重新设置步骤
			this.$pubsub.subscribe('setGuideSceneFulfilledEvent', () => {
				this.$pubsub.publish('resetGuideSteps', this.guideSteps)
			})

		},
		// 获取步骤描述
		getStepDesc(key) {
			let str = 'create_property_tips_' + key
			// 没有匹配到就返回空
			return this.$store.getters.guideDescConfig.property[str] || ''
		},
		// 获取物业
		homelist(page) {
			if (page) {
				this.pagesObj.page = page
			} else {
				this.pagesObj.page = 1
			}
			return new Promise((resolve, reject) => {
				this.$http
					.post('/home/homelist', {
						hrid: this.$store.getters.loginid,
						penum: this.$store.getters.penum,
						query: this.propertyQuery,
						page: this.pagesObj.page,
						pagesize: this.pagesObj.pagesize,
						orderby: this.propertyOrderby,
					}, {
						loadTarget: this.$refs.propertyListBox
					})
					.then((response) => {
						if (response.data.success) {
							this.homeList = response.data.content;
							this.peNumLimit = response.data.isPeNumOverLimit;

							this.pagesObj = {
								page: response.data.page,
								pagesize: response.data.pagesize,
								total: response.data.total
							}
							resolve()
						} else {
							this.$alert(response.data.message, this.$t('common.alertPrompt'), {
								type: 'error',
								center: true
							})
							reject()
							// showDialog(_this.pageText.PROMPT, response.data.message, 'error', '', [_this.pageText.OK]);
						}
						// loading.close()
					})
			})
		},
		// 切换物业
		switchCurrentInfo(item) {
			if (this.$store.getters.penum != item.penum) {
				this.$refs.webLayout.headerChangePeDetail(item.penum, '');
			}
		},
		// 点击搜索
		searchEvent() {
			this.homelist()
		},
		// 创建物业
		createProperty() {
			if (this.peNumLimit) {
				return this.$alert(this.$t('propertyList.numberLimit'), this.$t('common.alertPrompt'), {
					type: 'error',
					center: true
				})
			} else {
				// _this.handleNextStep()
				this.drawer = true
				if (this.$store.getters.isShowGuide) {
					this.$nextTick(() => {
						setTimeout(() => {
							window.guideDriver.moveNext()
						}, 350)
					})
				}
			}

		},
		// 关闭弹窗
		closeDrawer() {
			console.log('close---')
			this.drawer = false
		},
		// 创建物业成功
		createPropertySuccess(penum) {
			// 如果是跟着向导创建的，将按时间降序，搜索关键字清空
			if (this.$store.getters.isShowGuide) {
				window.guideDriver.destroy()
				this.propertyOrderby = 'crttime_desc'
				this.propertyQuery = ''
				this.homelist().then(() => {
					this.$nextTick(() => {
						window.guideDriver.drive(this.guideSteps.length - 1)
					})
				}).catch(() => { })
			} else {
				this.homelist()
			}
			this.$refs.webLayout.headerChangePeDetail(penum);
		},
		// 开启向导的回调
		openGuideCallback() {
			this.closeDrawer()
		}
	}
}